import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../assets/arrow.png";
import { url, ak } from "../pages/HomePage";

const categories = [
  "Kotisairaanhoito",
  "Kotihoito",
  "Henkilökohtainen avustus",
  "Lastenhoito",
  "Siivous",
];

function GigInfo() {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetch(url + "/admin/user/list" + ak, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const tmpEmployeeList = [];
        for (const key in data) {
          const employee = {
            id: key,
            ...data[key],
          };
          tmpEmployeeList.push(employee);
        }
        setEmployees(tmpEmployeeList);
      });

    const timezoneOffset = new Date(state.start).getTimezoneOffset();
    const hoursToSubtract = timezoneOffset === 120 ? 2 : 3;

    if (state.list === false) {
      state.start.setHours(state.start.getHours() - hoursToSubtract);
      state.end.setHours(state.end.getHours() - hoursToSubtract);
    }
  }, [state.start, state.end, state.list]);

  console.log(state);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("fi-FI", {
      timeZone: "Europe/Helsinki",
    });
    const formattedTime = date
      .toLocaleTimeString("fi-FI", { timeZone: "Europe/Helsinki" })
      .slice(0, -3);

    return `${formattedDate} kello ${formattedTime}`;
  }

  function createCategory() {
    if (state.category === 1) {
      return categories[0];
    } else if (state.category === 2) {
      return categories[1];
    } else if (state.category === 3) {
      return categories[2];
    } else if (state.category === 4) {
      return categories[3];
    } else if (state.category === 5) {
      return categories[4];
    } else {
      return categories[5];
    }
  }

  function findObjectByValue(user) {
    for (let i = 0; i < employees.length; i++) {
      if (employees[i].id === user) {
        return employees[i].fullname;
      }
    }
    return null;
  }

  return (
    <div className="w-full h-[90%] flex flex-col items-center justify-center relative">
      <img
        src={arrow}
        alt="takaisin"
        className="absolute h-6 px-8 mb-4 cursor-pointer top-16 left-10"
        onClick={() => window.history.back()}
      ></img>
      <div className="w-1/3 h-auto card rounded-xl p-7 ">
        <p className="pb-4 text-3xl font-bold">{state.admin_title}</p>
        <p className="text-xl ">
          <b>Otsikko: </b>
          {state.title}
        </p>
        <p className="text-xl ">
          <b>Osoite: </b>
          {state.address}
        </p>
        <p className="text-xl ">
          <b>Kaupunki: </b>
          {state.city}
        </p>
        <p className="text-xl ">
          <b>Alkaa: </b>
          {formatDate(state.start.toString())}
        </p>
        <p className="text-xl ">
          <b>Loppuu: </b>
          {formatDate(state.end.toString())}
        </p>
        <p className="text-xl ">
          <b>Kategoria: </b>
          {createCategory()}
        </p>
        <p className="text-xl ">
          <b>Kielitaito: </b>
          {state.language.toString().replaceAll(",", ", ")}
        </p>
        <p className="text-xl ">
          <b>Yleistietoa: </b>
          {state.public_description.replace(/-n-/g, " ")}
        </p>
        {state.status === 2 ? (
          <p className="text-xl ">
            <b>Suorittaja: </b>
            {state.username}
          </p>
        ) : (
          <p className="text-xl ">
            <b>Varattu: </b>
            {state.status === 1 ? "Kyllä" : "Ei"}
          </p>
        )}
        {state.status === 1 ? (
          <p className="text-xl ">
            <b>Kenelle: </b>
            {findObjectByValue(state.user)}
          </p>
        ) : (
          <></>
        )}
        <p className="text-xl ">
          <b>Asiakkaan nimi: </b>
          {state.customer}
        </p>
        <div className="flex justify-between w-full ">
          <button
            className="w-32 h-12 mt-8 font-bold text-white rounded bg-main text-md drop-shadow-md"
            onClick={() =>
              navigate("/keikka/hallinnointi", {
                state: {
                  title: state.title,
                  admin_title: state.admin_title,
                  public_description: state.public_description,
                  confidential_description: state.confidential_description,
                  start: state.start,
                  end: state.end,
                  address: state.address,
                  city: state.city,
                  category: state.category,
                  language: state.language,
                  status: state.status,
                  customer: state.customer,
                  id: state.id,
                },
              })
            }
          >
            Muokkaa
          </button>
          {state.status === 2 ? (
            <button
              className="w-32 h-12 mt-8 ml-6 font-bold text-white rounded bg-main text-md drop-shadow-md"
              onClick={() => {
                navigate("/keikka/tiedot/raportti", { state: state });
              }}
            >
              Raporttiin
            </button>
          ) : (
            <></>
          )}
          <button
            className="w-32 h-12 mt-8 ml-6 font-bold text-white rounded bg-main text-md drop-shadow-md"
            onClick={() => {
              if (
                window.confirm(
                  `Haluatko varmasti poistaa keikan ${state.title}`
                )
              ) {
                fetch(url + `/admin/gig/${state.id}` + ak, {
                  method: "DELETE",
                  credentials: "include",
                }).then(() => {
                  window.location = "/koti";
                });
              }
            }}
          >
            Poista
          </button>
          {state.status === 1 ? (
            <button
              className="w-32 h-12 mt-8 ml-6 font-bold text-white rounded bg-main text-md drop-shadow-md"
              onClick={() => {
                if (
                  window.confirm(
                    `Haluatko varmasti vapauttaa keikan ${state.title}`
                  )
                ) {
                  fetch(url + `/admin/gig/reserve/${state.id}/free` + ak, {
                    method: "POST",
                    credentials: "include",
                  }).then(() => {
                    window.location.href = "/koti";
                  });
                }
              }}
            >
              Vapauta
            </button>
          ) : (
            <></>
          )}
          {state.status === 0 ? (
            <button
              className="w-32 h-12 mt-8 ml-6 font-bold text-white rounded bg-main text-md drop-shadow-md"
              onClick={() =>
                navigate("/keikka/varaa", {
                  state: {
                    id: state.id,
                    title: state.title,
                    public_description: state.public_description,
                    confidential_description: state.confidential_description,
                    start: state.start,
                    end: state.end,
                    address: state.address,
                    city: state.city,
                    category: state.category,
                    language: state.language,
                  },
                })
              }
            >
              Varaa
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default GigInfo;
